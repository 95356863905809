import React from 'react';
import {
  Box,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import Image from 'next/image';
import { IImage } from '@/shared/types';
import { Parser, ReplacementsMap, GradientButton } from '@/shared/ui';
import { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import { handleActionButtonClick } from '@/utils';

interface PresentationBannerProps {
  title: string;
  description: string;
  buttonText: string;
  imageDesktop: IImage;
  imageMobile: IImage;
  logo: IImage;
}

export const PresentationBannerTambadana = (props: PresentationBannerProps) => {
  const { imageDesktop, imageMobile, logo, title, description, buttonText } =
    props;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const xsSmMd = useMediaQuery(theme.breakpoints.down('md'));

  const titleReplacementsMap: ReplacementsMap = {
    i: (domNode: Element, options: HTMLReactParserOptions) => {
      return (
        <Box
          component="span"
          sx={{
            color: theme.palette.primary.main,
            whiteSpace: 'pre',
          }}
        >
          {domToReact(domNode.children, options)}
        </Box>
      );
    },
  };

  const descriptionReplacementsMap: ReplacementsMap = {
    p: (domNode: Element, options: HTMLReactParserOptions) => {
      return (
        <Typography
          color="common.onPrimaryContainer"
          variant={isMobile ? 'text7' : 'headline6'}
          fontWeight={400}
          sx={{
            fontSize: '1.25rem',
          }}
        >
          {domToReact(domNode.children, options)}
        </Typography>
      );
    },
  };

  return (
    <Container
      sx={{
        mb: {
          xs: 3,
          md: 6,
          lg: 8,
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {isDesktop ? (
          <Box
            mt={{
              md: 4,
            }}
            sx={{
              background: theme.palette.common.white,
              borderRadius: {
                xs: 2,
                md: 4,
              },
              position: 'absolute',
              width: '100%',
              height: 'calc(100% - 32px)',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '38%',
                bottom: '-155px',
              }}
            >
              <Image
                alt=""
                src={logo?.data?.attributes?.url}
                width={220}
                height={220}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                right: '-71px',
                top: '20px',
              }}
            >
              <Image
                alt=""
                src={logo?.data?.attributes?.url}
                width={180}
                height={180}
              />
            </Box>
          </Box>
        ) : null}

        <Box
          pt={{
            md: 4,
          }}
          sx={{
            borderRadius: {
              xs: 2,
              sm: 4,
            },
            overflow: 'hidden',
            zIndex: 2,
            position: 'relative',
            background: theme.palette.common.white,
            [theme.breakpoints.up('lg')]: {
              background: `url("${imageDesktop?.data?.attributes?.url}") no-repeat right 45px top`,
              backgroundSize: 'contain',
            },
            [theme.breakpoints.up('md')]: {
              background: `url("${imageDesktop?.data?.attributes?.url}") no-repeat right 45px bottom`,
              backgroundSize: '400px',
            },
          }}
        >
          <Box
            p={{
              xs: 2,
              md: 4,
            }}
            pb={{
              xs: 26,
              md: 4,
            }}
            mt={{
              xs: 0,
              md: 4,
            }}
            sx={{
              [theme.breakpoints.down('md')]: {
                background: `url("${imageMobile?.data?.attributes?.url}") no-repeat center bottom`,
                backgroundSize: '250px',
              },
            }}
          >
            {xsSmMd ? (
              <Box
                sx={{
                  position: 'absolute',
                  right: {
                    xs: '-74px',
                  },
                  top: '85px',
                }}
              >
                <Image
                  alt=""
                  src={logo?.data?.attributes?.url}
                  width={150}
                  height={150}
                />
              </Box>
            ) : null}
            <Grid container>
              <Grid item xs={12} md={7}>
                <Box
                  mb={{ xs: 2.5, md: 4 }}
                  sx={{
                    color: theme.palette.common.onPrimaryContainer,
                    fontWeight: 700,

                    '& > *': {
                      m: 0,
                      lineHeight: {
                        xs: '47.5px',
                        sm: 'unset',
                      },
                      fontSize: {
                        xs: '2.375rem',
                        md: '3rem',
                        lg: '4.3rem',
                      },
                    },
                  }}
                >
                  <Parser data={title} redefine={titleReplacementsMap} />
                </Box>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} md={6} lg={5}>
                <Box mb={{ xs: 2.5, md: 5 }}>
                  <Parser
                    data={description}
                    redefine={descriptionReplacementsMap}
                  />
                </Box>

                <GradientButton
                  onClick={handleActionButtonClick}
                  fullWidth={isMobile}
                  animate={true}
                  sx={{
                    py: {
                      xs: 1.5,
                      md: 2,
                    },
                    px: {
                      xs: 1,
                      md: 5,
                    },
                    minWidth: {
                      md: 360,
                    },
                  }}
                >
                  {buttonText}
                </GradientButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
