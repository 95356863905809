import React from 'react';
import { Footer } from '@/shared/ui';
import { useCommonApi } from '@/shared/contexts';
import { getLinksFromNavData, NavLink } from '@/shared/lib';

export const MainLayoutFooter = () => {
  const commonApiContext = useCommonApi();

  const footerContent = commonApiContext?.layoutSettings?.attributes?.footer;

  const footerNavigationItems =
    commonApiContext?.layoutSettings?.attributes?.footerNavigation || [];

  const links: NavLink[] = getLinksFromNavData(footerNavigationItems);

  return footerContent ? (
    <Footer content={footerContent} links={links} />
  ) : null;
};
