import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Script from 'next/script';
import { useConfig } from '@/shared/hooks';
import { useCommonApi } from '@/shared/contexts';

export const Chatbot = () => {
  const appConfig = useConfig();
  const commonApiContext = useCommonApi();

  const chatbotOpen = () => {
    window?.zE?.('messenger', 'open');
  };

  const chatbotSettings =
    commonApiContext?.layoutSettings?.attributes.features?.chatbot;

  if (!chatbotSettings?.enabled) {
    return null;
  }

  const bgProps = {
    backgroundImage: `url(${chatbotSettings?.buttonImage?.data?.attributes.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
  };

  return (
    <>
      <Button
        aria-label="open chatbot"
        onClick={chatbotOpen}
        sx={{
          ...bgProps,
          pt: 0.75,
          bgcolor: chatbotSettings?.buttonColor || 'tertiary.40',
          alignItems: 'start',
          position: 'fixed',
          bottom: 16,
          right: 16,
          borderRadius: 1.25,
          width: 92,
          height: 84,
          zIndex: 1000,
          '&:hover': { ...bgProps },
        }}
      >
        <Typography variant="headline8">
          {chatbotSettings?.buttonText}
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            width: 13,
            height: 13,
            bgcolor: 'inherit',
            transform: 'rotate(45deg)',
            right: -6,
            top: 0,
            bottom: 0,
            margin: 'auto',
          }}
        />
      </Button>
      <Script
        src={`https://static.zdassets.com/ekr/snippet.js?key=${appConfig?.chatbotClientKey}`}
        id="ze-snippet"
        strategy="beforeInteractive"
      />
    </>
  );
};
