import { Header } from '@/shared/ui';
import { getLinksFromNavData, NavLink } from '@/shared/lib';
import { useCommonApi } from '@/shared/contexts';

export const MainLayoutHeader = () => {
  const commonApiContext = useCommonApi();
  const commonApiAttributes = commonApiContext?.layoutSettings?.attributes;
  const headerNavigationItems = commonApiAttributes?.headerNavigation || [];

  const links: NavLink[] = getLinksFromNavData(headerNavigationItems);

  return (
    <Header
      accountBtnText={commonApiAttributes?.header?.accountBtnText}
      showAccountBtn={commonApiAttributes?.header?.showAccountBtn}
      menuCloseText={commonApiAttributes?.header?.menuCloseText}
      menuBtnText={commonApiAttributes?.header?.menuBtnText}
      links={links}
      supportPhoneNumber={commonApiAttributes?.supportPhoneNumber}
      supportEmail={commonApiAttributes?.supportEmail}
      socialNetworks={commonApiAttributes?.socialNetworks}
    />
  );
};
