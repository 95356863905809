import React, { PropsWithChildren } from 'react';
import { Box, Stack } from '@mui/material';
import { MainLayoutHeader } from './main-layout-header';
import { MainLayoutFooter } from './main-layout-footer';
import { Chatbot } from '@/features/chatbot';

export type MainLayoutProps = PropsWithChildren;

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      <Stack
        sx={{
          height: 1,
          margin: 'auto',
        }}
      >
        <MainLayoutHeader />
        <Box component="main" pt={{ sm: 2 }} sx={{ flex: '1 0 auto' }}>
          {children}
        </Box>
        <MainLayoutFooter />
        <Chatbot />
      </Stack>
    </>
  );
};
