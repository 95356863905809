import React from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
} from '@mui/material';
import Image from 'next/image';
import { IImage } from '@/shared/types';
import { Parser, ReplacementsMap, GradientButton } from '@/shared/ui';
import { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import { handleActionButtonClick } from '@/utils';

interface PresentationBannerProps {
  title: string;
  description: string;
  buttonText: string;
  imageDesktop: IImage;
  imageMobile: IImage;
  logo: IImage;
}

export const PresentationBannerCeyloan = (props: PresentationBannerProps) => {
  const { imageDesktop, imageMobile, logo, title, description, buttonText } =
    props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const descriptionReplacementsMap: ReplacementsMap = {
    p: (domNode: Element, options: HTMLReactParserOptions) => {
      return (
        <Typography
          variant={isMobile ? 'text7' : 'text6'}
          sx={{
            fontSize: {
              xs: '18px',
              md: '24px',
            },
          }}
        >
          {domToReact(domNode.children, options)}
        </Typography>
      );
    },
  };

  return (
    <Container
      sx={{
        mb: {
          xs: 3,
          md: 6,
          lg: 8,
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {isDesktop ? (
          <Box
            mt={{
              md: 4,
            }}
            sx={{
              background: theme.palette.common.white,
              borderRadius: {
                xs: 2,
                sm: 4,
              },
              position: 'absolute',
              width: '100%',
              height: 'calc(100% - 32px)',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '30%',
                bottom: '-94px',
              }}
            >
              <Image
                alt=""
                src={logo?.data?.attributes?.url}
                width={270}
                height={270}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                right: '16%',
                top: '-101px',
                transform: 'scaleX(-1) rotate(90deg)',
              }}
            >
              <Image
                alt=""
                src={logo?.data?.attributes?.url}
                width={270}
                height={270}
              />
            </Box>
          </Box>
        ) : null}

        <Box
          pt={{
            md: 4,
          }}
          sx={{
            borderRadius: {
              xs: 2,
              md: 4,
            },
            overflow: 'hidden',
            zIndex: 2,
            position: 'relative',
            background: theme.palette.common.white,
            [theme.breakpoints.up('lg')]: {
              background: `url("${imageDesktop?.data?.attributes?.url}") no-repeat right 45px top`,
              backgroundSize: 'contain',
            },
            [theme.breakpoints.up('md')]: {
              background: `url("${imageDesktop?.data?.attributes?.url}") no-repeat right 45px bottom`,
              backgroundSize: '400px',
            },
          }}
        >
          <Box
            p={{
              xs: 2,
              md: 4,
            }}
            pb={{
              xs: 26,
              md: 4,
            }}
            mt={{
              xs: 0,
              md: 4,
            }}
            sx={{
              [theme.breakpoints.down('md')]: {
                background: `url("${imageMobile?.data?.attributes?.url}") no-repeat center bottom`,
                backgroundSize: '250px',
              },
            }}
          >
            <Grid container>
              <Grid item xs={12} md={7}>
                <Box mb={{ xs: 2.5, md: 4 }}>
                  <Typography
                    variant={isMobile ? 'headline5' : 'headline1'}
                    fontWeight={700}
                    color="common.onPrimaryContainer"
                    sx={{
                      m: 0,
                      lineHeight: {
                        xs: '40px',
                        sm: 'unset',
                      },
                      fontSize: {
                        xs: '1.9rem',
                        md: '3rem',
                        lg: '4rem',
                      },
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={{ xs: 2.5, md: 5 }}>
                  <Parser
                    data={description}
                    redefine={descriptionReplacementsMap}
                  />
                </Box>

                <GradientButton
                  onClick={handleActionButtonClick}
                  fullWidth={isMobile}
                  animate
                  sx={{
                    py: {
                      xs: 1.5,
                      md: 2,
                    },
                    px: {
                      xs: 1,
                      md: 5,
                    },
                    minWidth: {
                      md: 280,
                    },
                  }}
                >
                  {buttonText}
                </GradientButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
